/* eslint-disable no-unused-vars */
import {
  businessInfoGuard,
  complianceInfoGuard,
  stripeConnectGuard,
  dueDiligenceGuard,
  widgetIntegrationGuard,
  pluginIntegrationGuard,
  integrationCompleteGuard,
  merchantDeclinedGuard,
} from "../constants/routeGuards";
import { OnboardingMerchant } from "../models/onboardingMerchant";
import { useSettings } from "./useSettings";

type RouteGuard = (merchant: OnboardingMerchant) => boolean;

export interface RouteGuards {
  businessInfo: RouteGuard;
  complianceInfo: RouteGuard;
  stripeConnect: RouteGuard;
  dueDiligence: RouteGuard;
  widgetIntegration: RouteGuard;
  pluginIntegration: RouteGuard;
  integrationComplete: RouteGuard;
  merchantDeclined: RouteGuard;
}

export const useRouteGuards = (): RouteGuards => {
  const { isDevelopmentMode } = useSettings();

  return {
    businessInfo: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || businessInfoGuard(merchant),
    complianceInfo: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || complianceInfoGuard(merchant),
    stripeConnect: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || stripeConnectGuard(merchant),
    dueDiligence: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || dueDiligenceGuard(merchant),
    widgetIntegration: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || widgetIntegrationGuard(merchant),
    pluginIntegration: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || pluginIntegrationGuard(merchant),
    integrationComplete: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || integrationCompleteGuard(merchant),
    merchantDeclined: (merchant: OnboardingMerchant) =>
      isDevelopmentMode || merchantDeclinedGuard(merchant),
  };
};
